import { useEffect } from "react"

import Cookies from "js-cookie"

import { useStateValue } from "state/state"
import { CONSENT_COOKIE_ID } from "constants/Site"
import { UPDATE_NEED_CONSENT } from "constants/StateActionTypes"

export const useCheckConsentCookie = (debug = false) => {
  const [, dispatch] = useStateValue()

  useEffect(() => {
    let cookie = Cookies.get(CONSENT_COOKIE_ID)
    dispatch({
      type: UPDATE_NEED_CONSENT,
      needConsent: !cookie || debug,
    })
  }, [dispatch, debug])
}
