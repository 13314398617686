import {
  UPDATE_EXTERNAL_LINK,
  UPDATE_NEED_CONSENT,
  UPDATE_SHOW_MODAL,
  UPDATE_EXPAND_ISI,
  UPDATE_LIGHTBOX_VIDEO,
  CLOSE_VIDEO_MODAL
} from "constants/StateActionTypes"

export const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_EXTERNAL_LINK:
      return {
        ...state,
        externalLink: action.url,
        video: null,
        videoTitle: null,
        showOverlay: action.url ? true : false,
        showModal: action.url ? true : false,
      }
    case UPDATE_NEED_CONSENT:
      return {
        ...state,
        needConsent: action.needConsent,
        showOverlay: action.needConsent,
        showModal: action.needConsent,
      }
    case UPDATE_SHOW_MODAL:
      return {
        ...state,
        videoTitle: action.showModal ? state.videoTitle : undefined,
        video: action.showModal ? state.video : undefined,
        showModal: action.showModal,
        showOverlay: action.showModal,
      }
    case CLOSE_VIDEO_MODAL:
      return {
        ...state,
        showModal: false,
        showOverlay: false,
      }
    case UPDATE_EXPAND_ISI:
      return {
        ...state,
        expandISI: action.expandISI,
        showOverlay: action.expandISI,
        externalLink: false,
      }
      case UPDATE_LIGHTBOX_VIDEO:
        return {
          ...state,
          externalLink: undefined,
          videoTitle: action.showModal ? state.videoTitle : undefined,
          video: action.video,
          showOverlay: action.video ? true : false,
          showModal: action.video ? true : false,
        }
    default:
      return state
  }
}

export const initialState = {
  externalLink: undefined,
  video: undefined,
  videoTitle: undefined,
  needConsent: true,
  showOverlay: false,
  showModal: false,
  expandISI: false,
}
