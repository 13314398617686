import React from "react"

const CloseSign = ({ classes }) => (
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;">
<path className={classes} d="M22.6,1.2L1.2,23"/>
<path class={classes} d="M1.2,1.2L22.6,23"/>
</svg>
)

export default CloseSign
