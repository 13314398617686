import React from "react"
import { Container } from "react-bootstrap"

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import classNames from "classnames"

import { useISIData } from "hooks/contentful/useISIData"
import { RICH_TEXT_OPTIONS } from "utils/contentfulRenderer"
import PlusSign from "assets/svg/plus-sign"
import MinusSign from "assets/svg/minus-sign"

import styles from "./ISI.module.scss"

const ExpandButton = ({ onClick, showLess, showLabel, hideLabel }) => {
  return (
    <button
      onClick={onClick}
      className={classNames([styles.expandButton], {
        [styles.active]: showLess,
      })}
    >
      <span className={styles.expandLabel}>
        {showLess ? hideLabel : showLabel}
      </span>
      <span className={styles.icon}>
        {showLess ? <MinusSign /> : <PlusSign />}
      </span>
    </button>
  )
}

const ISI = ({ onExpandClick, showLess }) => {
  const { header, boxedList, content, showLabel, hideLabel } = useISIData()

  return (
    <Container role={"complementary"} fluid as={"section"} className={styles.isiContainer}>
      <Container
        className={classNames([styles.isi], {
          [styles.isiTray]: onExpandClick,
        })}
      >
        {onExpandClick && (
          <ExpandButton
            showLess={showLess}
            showLabel={showLabel}
            hideLabel={hideLabel}
            onClick={onExpandClick}
          />
        )}
        <div className={styles.priorityIsiBlocks}>
          <div className={styles.isiBlock}>
            <h5 className={styles.header}>{header}</h5>
            {boxedList && (
              <dl className={styles.warnings}>
                <dt className={styles.warningsHeader}>{boxedList.header}</dt>
                {boxedList.items.map((item, index) => (
                  <dd className={styles.warningsItem} key={index}>
                    {item}
                  </dd>
                ))}
              </dl>
            )}
          </div>
          {content
            .filter((item) => item.order === -1)
            .map((item, index) => (
              <div key={index} className={styles.isiBlock}>
                {item.title && <h5 className={styles.header}>{item.title}</h5>}
                {item.body && (
                  <div className={styles.richText}>
                    {documentToReactComponents(item.body, RICH_TEXT_OPTIONS)}
                  </div>
                )}
              </div>
            ))}
        </div>
        {content.map((item, index) => (
          <div
            key={index}
            className={classNames([styles.isiBlock], {
              [styles.priority]: item.order === -1,
              [styles.last]: index === content.length - 1,
            })}
          >
            {item.title && <h5 className={styles.header}>{item.title}</h5>}
            {item.body && (
              <div className={styles.richText}>
                {documentToReactComponents(item.body, RICH_TEXT_OPTIONS)}
              </div>
            )}
          </div>
        ))}
      </Container>
    </Container>
  )
}

export default ISI
