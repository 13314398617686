import { useStaticQuery, graphql } from "gatsby"

export const useISIData = () => {
  const { isi } = useStaticQuery(graphql`
    query {
      isi: contentfulHcpIsiAssembly(title: { eq: "ISI" }) {
        header: label
        boxedList {
          ...BulletListFragment
        }
        content {
          ...RichTopicFragment
        }
        showLabel
        hideLabel
      }
    }
  `)

  return {
    ...isi,
    content: isi.content.map((item) => {
      return {
        title: item.title,
        order: item.order,
        body: item.richContent.json,
      }
    }),
  }
}
