import React from "react"

import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"

import { formatHref } from "./helpers"

import ExternalLink from "components/Modal/ExternalLink"

const Bold = ({ children }) => <strong>{children}</strong>
const Italic = ({ children }) => <em>{children}</em>
const Text = ({ children }) => <p>{children}</p>
const Header = ({ children }) => <h5>{children}</h5>

export const EXTERNAL_RICH_TEXT_OPTIONS = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return <Text>{children}</Text>
    },
    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      const useNewWindow = node.data.target.fields.useNewWindow

      return useNewWindow ? (
        <ExternalLink url={formatHref(node.data.target.fields.url)}>
          {children}
        </ExternalLink>
      ) : (
        <a href={formatHref(node.data.target.fields.url)}>{children}</a>
      )
    },
  },
}

export const RICH_TEXT_OPTIONS = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    [MARKS.ITALIC]: (text) => <Italic>{text}</Italic>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return <Text>{children}</Text>
    },
    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      return (
        <a
          target={node.data.target.fields.useNewWindow && "_blank"}
          href={formatHref(node.data.target.fields.url)}
        >
          {children}
        </a>
      )
    },
  },
}

export const HERO_CAPTION_RICH_TEXT_OPTIONS = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Header>{children}</Header>,
  },
}
