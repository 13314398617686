import React from "react"

const PlusSign = ({ classes }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    className={classes}
  >
    <line x1="14" y1="7" x2="14" y2="21" />
    <line x1="21" y1="14" x2="7" y2="14" />
  </svg>
)

export default PlusSign
