import React from "react"

import styles from "./CTA.module.scss"

const CTA = ({ children, classes, clickHandler }) => {
  return (
    <button onClick={clickHandler} className={`${styles.cta} ${classes}`}>
      {children}
    </button>
  )
}

CTA.defaultProps = {
  classes: "",
}

export default CTA
