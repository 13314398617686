import React from "react"
import { Container } from "react-bootstrap"

import styles from "./References.module.scss"

const References = ({ referencesHeader, references = [] }) => {
  return (
    <Container fluid as={"section"} className={styles.references}>
      <Container>
        <h4 className={styles.header}>{referencesHeader}</h4>
        <ol className={styles.list}>
          {references.map((reference, index) => {
            return (
              <li className={styles.listItem} key={index} dangerouslySetInnerHTML={{__html: reference}}></li>
            )
          })}
        </ol>
      </Container>
    </Container>
  )
}

export default References
