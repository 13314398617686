import React from "react"
import { Container } from "react-bootstrap"

import classNames from "classnames"

import { useHeaderData } from "hooks/contentful/useHeaderData"
import { formatHref } from "utils/helpers"
import ExternalLinkIcon from "assets/svg/external-link"

import ExternalLink from "components/Modal/ExternalLink"

import styles from "./MobileUtilityNav.module.scss"

const MobileUtilityNav = ({ reference }) => {
  const { mobileUtilityMenuElements: menuElements } = useHeaderData()

  return (
    <Container
      ref={reference}
      fluid
      as={"section"}
      className={styles.mobileUtilityNav}
    >
      <ul className={styles.menu}>
        {menuElements.map((element) => {
          return (
            <li className={styles.menuItem} key={element.label}>
              {element.action.useNewWindow && element.showIcon ? (
                <ExternalLink
                  url={formatHref(element.action.url)}
                  className={classNames([styles.link], {
                    [styles.capsule]: element.showIcon,
                  })}
                >
                  {element.label}
                  {element.showIcon && (
                    <ExternalLinkIcon classes={styles.icon} />
                  )}
                </ExternalLink>
              ) : (
                <a
                  className={classNames([styles.link], {
                    [styles.capsule]: element.showIcon,
                  })}
                  target={element.action.useNewWindow && "_blank"}
                  href={formatHref(element.action.url)}
                >
                  {element.label}
                </a>
              )}
            </li>
          )
        })}
      </ul>
    </Container>
  )
}

export default MobileUtilityNav
