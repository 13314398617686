import React from "react"

const CTAArrow = ({ classes }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    className={classes}
  >
    <path
      d="M10,8.1l-3.7,3.7c-0.4,0.4-0.4,1.1,0,1.5c0.4,0.4,1.1,0.4,1.5,0l5.5-5.5c0.4-0.4,0.4-1.1,0-1.5
L7.8,0.8c-0.4-0.4-1.1-0.4-1.5,0s-0.4,1.1,0,1.5L10,6l1,1.1L10,8.1z"
    />
  </svg>
)

export default CTAArrow
