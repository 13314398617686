import { useEffect } from "react"

export const useShowOverlay = (show, topLevel) => {
  useEffect(() => {
    if (show) {
      document.body.classList.add("overlay")
    } else {
      document.body.classList.remove("overlay")
    }

    if (topLevel) {
      document.body.classList.add("topLevel")
    } else {
      document.body.classList.remove("topLevel")
    }
  }, [show, topLevel])
}
