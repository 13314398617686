import React from "react"

import { formatHref } from "utils/helpers"

import ExternalLink from "components/Modal/ExternalLink"

import styles from "./FooterNav.module.scss"

const FooterNav = ({ menuElements }) => {
  const renderLink = ({ action, image, label, alt }) => {
    return (
      <a className={styles.link} href={formatHref(action.url)}>
        {image?.localFile?.publicURL && (
          <img
            className={styles.icon}
            alt={alt}
            src={image.localFile?.publicURL}
          />
        )}
        {label}
      </a>
    )
  }

  const renderExternalLink = ({ action, image, label, alt }) => {
    return (
      <ExternalLink className={styles.link} url={formatHref(action.url)}>
        {image?.localFile?.publicURL && (
          <img
            className={styles.icon}
            alt={alt}
            src={image.localFile?.publicURL}
          />
        )}
        {label}
      </ExternalLink>
    )
  }

  return (
    <nav className={styles.footerNav}>
      <ul className={styles.menu}>
        {menuElements.map((item, index) => {
          return (
            <li className={styles.item} key={index}>
              {item.action.useNewWindow
                ? renderExternalLink(item)
                : renderLink(item)}
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

export default FooterNav
