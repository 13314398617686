import React, { useState, useEffect, useRef } from "react"
import { Container } from "react-bootstrap"

import { ScrollTrigger, gsap } from "gsap/all"
import classNames from "classnames"

import { useHeaderData } from "hooks/contentful/useHeaderData"
import { useMobileHeaderData } from "hooks/contentful/useMobileHeaderData"

import MainNav from "../MainNav/MainNav"
import MobileHeaderNav from "../MobileHeaderNav/MobileHeaderNav"

import styles from "./HeaderNav.module.scss"

gsap.registerPlugin(ScrollTrigger)

const HomeNav = ({ url, imageURL, alt }) => {
  return (
    <div className={styles.homeNav}>
      <a href={url} className={styles.link}>
        <img alt={alt} src={imageURL} />
      </a>
    </div>
  )
}

const MobileMenuHamburger = ({ show, onClick, label }) => {
  return (
    <button
      onClick={onClick}
      className={classNames([styles.mobileHamburger], { [styles.show]: show })}
    >
      <span className={styles.srOnly}>Toggle navigation</span>
      <span className={styles.iconBar} />
      <span className={styles.iconBar} />
      <span className={styles.iconBar} />
      <span className={styles.label}>{label}</span>
    </button>
  )
}

const HeaderNav = ({ headerOffset }) => {
  const { homeNavAction, menuElements } = useHeaderData()
  const { showLabel, hideLabel } = useMobileHeaderData()
  const [showMobileNav, setShowMobileNav] = useState(false)
  const [headerHeight, setHeaderHeight] = useState(0)

  const toggle = () => setShowMobileNav(!showMobileNav)
  const reference = useRef(null)

  useEffect(() => {
if(typeof window === "object"){
  if(window.innerWidth < 992){
    ScrollTrigger.create({
      trigger: "#header",
      pin: true,
      start: "top top",
      pinSpacing: false,
      endTrigger: "html",
      end: "bottom top",
      anticipatePin: 1,
    })
  } else {
    ScrollTrigger.create({
      trigger: "#header",
      pin: true,
      start: 168,
      pinSpacing: false,
      endTrigger: "html",
      end: "bottom top",
      anticipatePin: 1,
      onToggle: self => {
        if(self.isActive){
          if(self.trigger.classList.contains('not--active')){
            self.trigger.classList.remove('not--active')
          }          
          self.trigger.classList.add('nav--disappear');
          self.trigger.classList.remove('nav--show');
        } else {
          if(!self.trigger.classList.contains('not--active')){
            self.trigger.classList.add('not--active');
          }          
          self.trigger.classList.add('nav--show');
          self.trigger.classList.remove('nav--disappear');
        }
    },
      onUpdate: self => {
        if(self.direction === -1){
          if(!self.trigger.classList.contains('not--active')){
            self.trigger.classList.add('not--active');
          }
          self.trigger.classList.add('nav--show');
          self.trigger.classList.remove('nav--disappear');
        } else {
          if(self.trigger.classList.contains('not--active')){
            self.trigger.classList.remove('not--active')
          }
          self.trigger.classList.add('nav--disappear');
          self.trigger.classList.remove('nav--show');
        }
      }    
    })
  }
  return () => {
    ScrollTrigger.refresh()
  }
}
    
  }, [])

  useEffect(() => {
    if(typeof window === "object"){
        if (window.scrollY > reference.current.offsetTop + headerOffset) {
          setHeaderHeight(reference.current.offsetHeight)
        } else {
          setHeaderHeight(
            headerOffset + reference.current.offsetHeight - window.scrollY
          )
        }
    }
  }, [showMobileNav, reference, headerOffset])

  return (
    <>
      <Container
        ref={reference}
        id="header"
        fluid
        role={"navigation"}
        as={"section"}
        className={styles.container}
      >
        <Container className={styles.childContainer}>
          <div className={styles.headerNav}>
            <HomeNav
              url={homeNavAction.action?.url}
              imageURL={homeNavAction.imageURL}
              alt={homeNavAction.alt}
            />
            <MobileMenuHamburger
              onClick={toggle}
              show={showMobileNav}
              label={showMobileNav ? hideLabel : showLabel}
            />
            <MainNav menuElements={menuElements} />
          </div>
        </Container>
      </Container>
      <MobileHeaderNav show={showMobileNav} headerHeight={headerHeight} />
    </>
  )
}

export default HeaderNav
