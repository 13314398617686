import React from "react"
import { Container } from "react-bootstrap"

import classNames from "classnames"

import { useHeaderData } from "hooks/contentful/useHeaderData"
import { formatHref } from "utils/helpers"
import ExternalLinkIcon from "assets/svg/external-link"
import CTAArrow from "assets/svg/cta-arrow"

import ExternalLink from "components/Modal/ExternalLink"

import styles from "./UtilityNav.module.scss"

const UtilityNav = () => {
  const { utilityMenuElements: menuElements } = useHeaderData()

  return (
    <Container role={"navigation"} fluid as={"section"} className={styles.utilityNav}>
      <Container as={"ul"} className={styles.menu}>
        {menuElements.map((element) => {
          return (
            <li
              className={classNames([styles.menuItem], {
                [styles.capsule]: element.showIcon,
                [styles.multiline]: !element.showIcon,
              })}
              key={element.label}
            >
              {element.action.useNewWindow && element.showIcon ? (
                <ExternalLink
                  url={formatHref(element.action.url)}
                  className={styles.link}
                >
                  {element.label}
                  {element.showIcon && (
                    <ExternalLinkIcon classes={styles.icon} />
                  )}
                </ExternalLink>
              ) : (
                <a
                  className={styles.link}
                  href={formatHref(element.action.url)}
                  target={element.action.useNewWindow && "_blank"}
                >
                  {element.label}
                  {element.showIcon && <CTAArrow classes={styles.icon} />}
                </a>
              )}
            </li>
          )
        })}
      </Container>
    </Container>
  )
}

export default UtilityNav
