import React from "react"

import classNames from "classnames"

import { useStateValue } from "state/state"
import { UPDATE_EXPAND_ISI } from "constants/StateActionTypes"

import ISI from "components/ISI/ISI"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"

import styles from "./ISITray.module.scss"

const ISITray = ({ show }) => {
  const [{ expandISI, video }, dispatch] = useStateValue()

  return (
    <aside
      className={classNames([styles.isiTray], {
        [styles.show]: show,
        [styles.expand]: expandISI,
      })}
      id="isi-container"
    >
      <ISI
        showLess={expandISI}
        onExpandClick={() => {
          const targetElement = document.querySelector('#isi-container');
          if (expandISI) {
            enableBodyScroll(targetElement)
          } else {
            disableBodyScroll(targetElement)
          }
          dispatch({
            type: UPDATE_EXPAND_ISI,
            expandISI: !expandISI,
          })
        }}
      />
    </aside>
  )
}

export default ISITray
