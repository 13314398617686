import React, { useRef, useEffect,useTransition} from "react"
import { Container } from "react-bootstrap"

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Cookies from "js-cookie"
import classNames from "classnames"

import {
  UPDATE_NEED_CONSENT,
  UPDATE_SHOW_MODAL,
  CLOSE_VIDEO_MODAL
} from "constants/StateActionTypes"
import { CONSENT_COOKIE_ID } from "constants/Site"
import { RICH_TEXT_OPTIONS } from "utils/contentfulRenderer"
import { formatHref } from "utils/helpers"
import { useStateValue } from "state/state"
import { useModalData } from "hooks/contentful/useModalData"
import CTAArrow from "assets/svg/cta-arrow"
import ExternalLink from "assets/svg/external-link"
import CloseSign from "assets/svg/close"

import CTA from "components/CTA/CTA"

import styles from "./Modal.module.scss"

const Modal = () => {
  const [{ externalLink, showModal, video }, dispatch] = useStateValue()
  const { gate, external,videoTitles } = useModalData()
  const modalContainer = useRef();
  const acceptConsent = () => {
    Cookies.set(CONSENT_COOKIE_ID, true, { expires: 365 })
    dispatch({
      type: UPDATE_NEED_CONSENT,
      needConsent: false,
    })
  }

  const cancelExternal = () => {
    dispatch({
      type: UPDATE_SHOW_MODAL,
      showModal: false,
    })
  }

  useEffect(() => { 
    if (showModal) modalContainer.current.focus()
  });
  return (
    <Container
      fluid
      as={"section"}
      className={classNames([styles.modalContainer], {
        [styles.show]: showModal,
        [styles.video]: video
      })}
    >
      <Container className={styles.flexContainer}>
        <aside
          className={classNames([styles.modal], {
            [styles.externalModal]: externalLink,
          })}
          ref={modalContainer}
          tabIndex={0}

        >
          {
            video ?
            <button
              onClick={() => dispatch({
                type: CLOSE_VIDEO_MODAL,
                showModal: false,
              })}
            ><img alt="close indicator" src="./close.svg"/></button>
            : null
          }
          {
            showModal  ?  video ? (
              <iframe title={videoTitles[video]} src={`https://player.vimeo.com/video/${video}`} width="640" height="360" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
            ) : null : null
          }
          {externalLink ? (
            <>
              {external.imageURL && (
                <img
                  className={styles.contentImage}
                  alt=""
                  src={external.imageURL}
                />
              )}
              {documentToReactComponents(external.content, RICH_TEXT_OPTIONS)}
            </>
          ) : video ? null : (
            <>{documentToReactComponents(gate.content, RICH_TEXT_OPTIONS)}</>
          )}
          <div className={styles.buttonContainer}>
            {externalLink ? (
              <>
                <CTA classes={styles.button} clickHandler={cancelExternal}>
                  <span className={styles.label}>{external.cancel.label}</span>
                  <CTAArrow classes={styles.icon} />
                </CTA>
                <a
                  className={styles.cta}
                  href={formatHref(externalLink)}
                  target="_blank"
                >
                  <span className={styles.label}>{external.confirm.label}</span>
                  <ExternalLink classes={styles.icon} />
                </a>
              </>
            ) : video ? null : (
              <>
                <a
                  className={styles.button}
                  href={formatHref(gate.cancel.url)}
                >
                  <span className={styles.label}>{gate.cancel.label}</span>
                  <ExternalLink classes={styles.icon} />
                </a>
                <CTA classes={styles.cta} clickHandler={acceptConsent}>
                  <span className={styles.label}>{gate.confirm.label}</span>
                  <CTAArrow classes={styles.icon} />
                </CTA>
              </>
            )}
          </div>
        </aside>
      </Container>
    </Container>
  )
}

export default Modal
