import React, { useState, useRef } from "react"

import { Link } from "gatsby"
import classNames from "classnames"

import { formatHref } from "utils/helpers"
import {
  NAVIGATION_ELEMENT,
  MENU_ELEMENT,
} from "constants/ContentfulContentTypes"

import styles from "./MainNav.module.scss"

let onKeyDownValue = (event) => {
  let el = event.target
  if (event.which === 13) {
    el.querySelector("ul").style.display = "block"
  }
}

const focusInCurrentTarget = ({ relatedTarget, currentTarget }) => {
  if (relatedTarget === null) return false
  // console.log('relatedTarget ', relatedTarget);
  // console.log('currentTarget ', currentTarget);
  var node = relatedTarget.parentNode

  while (node !== null) {
    if (node === currentTarget) return true
    node = node.parentNode
  }

  return false
}

let onMenuBlur = (event) => {
  if (!focusInCurrentTarget(event)) {
    event.currentTarget.querySelector("ul").style.display = "none"
    // console.log('table blurred');
  }
}

const MainNav = ({ menuElements }) => {
  const [showSubMenu, setShowSubMenu] = useState('')
  const renderMenuElement = ({ label, menuElements }) => {
    return (
      <li className={classNames([styles.menuItem])} key={label}>
        <button
          className={classNames([styles.submenu, 
            {
              [styles.active]: showSubMenu === label,
            }
          ])}
          onKeyPress={(e) => onKeyDownValue(e)}
          onBlur={(e) => onMenuBlur(e)}
        >
          {label}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="9"
            viewBox="0 0 14 9"
          >
            <path
              className={styles.submenuIcon}
              d="M5.9,4.7L2.2,1C1.8,0.5,1.1,0.5,0.7,1c-0.4,0.4-0.4,1.1,0,1.5L6.2,8c0.4,0.4,1.1,0.4,1.5,0l5.5-5.5
      c0.4-0.4,0.4-1.1,0-1.5c-0.4-0.4-1.1-0.4-1.5,0L8.1,4.7L7,5.7L5.9,4.7z"
            />
          </svg>
          <ul className={styles.submenuItems}>
            {menuElements.map(({ label, action }) => {
              return (
                <li key={label} className={styles.submenuItem}>
                  <Link
                    to={formatHref(action.url)}
                    className={styles.submenuAnchor}
                    activeClassName={styles.active}
                    getProps={({location}) => {
                      if (location.pathname === "/palisade" || location.pathname === "/ramses") {
                        setShowSubMenu('Key Studies');
                      } else if (location.pathname === "/access" || location.pathname === "/resources") {
                        setShowSubMenu('Access & Resources');
                      } else {
                        setShowSubMenu('');
                      }
                    }}
                  >
                    {label}
                  </Link>
                </li>
              )
            })}
          </ul>
        </button>
      </li>
    )
  }

  const renderNavigationElement = ({ label, action }) => {
    return (
      <li className={classNames([styles.menuItem])} key={label}>
        <Link to={formatHref(action.url)} className={styles.anchor} activeClassName={styles.active}>
          {label}
        </Link>
      </li>
    )
  }

  return (
    <nav aria-label={"primary navigation"} className={styles.mainNav}>
      <ul className={styles.menu}>
        {menuElements.map((element, index) => {
          let renderedMenuItem

          switch (element.__typename) {
            case NAVIGATION_ELEMENT:
              renderedMenuItem = renderNavigationElement(element)
              break
            case MENU_ELEMENT:
              renderedMenuItem = renderMenuElement(element)
              break
            default:
              break
          }

          return renderedMenuItem
        })}
      </ul>
    </nav>
  )
}

export default MainNav
