import { useStaticQuery, graphql } from "gatsby"

export const useModalData = () => {
  const { gate, external } = useStaticQuery(graphql`
    query {
      gate: contentfulHcpModalAssembly(title: { eq: "Gate Modal" }) {
        title
        content {
          ...RichTopicFragment
        }
        confirm {
          ...NavigationElementFragment
        }
        cancel {
          ...NavigationElementFragment
        }
      }
      external: contentfulHcpModalAssembly(title: { eq: "Exit Interstitial" }) {
        title
        image {
          localFile {
            publicURL
          }
        }
        content {
          ...RichTopicFragment
        }
        confirm {
          ...NavigationElementFragment
        }
        cancel {
          ...NavigationElementFragment
        }
      }
    }
  `)

  return {
    gate: {
      content: gate.content?.richContent?.json,
      confirm: {
        label: gate.confirm?.label,
      },
      cancel: {
        label: gate.cancel?.label,
        url: gate.cancel?.action?.url,
      },
    },
    videoTitles: {
      935958877:"Find out about staffing considerations for your practice",
      936262247:"Review office space considerations for the three phases of treatment",
      935958786:"Learn more about appropriate patients for PALFORZIA and available financial assistance programs",
      935958939:"Consider these points when scheduling patient visits throughout the treatment process.",
      499467503:"how to become REMS certified.",
      499787224:"how to certify in the REMS program as a healthcare setting",
      499467716:"how to enroll the patient in the PALFORZIA REMS Program",
      499468130:"Watch this step-by-step video to learn how to order your Office Dose Kit",
      499467904:"How to complete the Prescription and Enrollment Form"
    },
    external: {
      content: external.content?.richContent?.json,
      imageURL: external.image?.localFile?.publicURL,
      confirm: {
        label: external.confirm?.label,
      },
      cancel: {
        label: external.cancel?.label,
      },
    },
  }
}
