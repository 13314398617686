import { useStaticQuery, graphql } from "gatsby"

export const useMobileHeaderData = () => {
  const { mobileHeader } = useStaticQuery(graphql`
    query {
      mobileHeader: contentfulHcpMenuAssembly(label: { eq: "Mobile Header" }) {
        content {
          ...MenuFragment
          ...NavigationElementFragment
          ...TopicFragment
        }
        showLabel
        hideLabel
      }
    }
  `)

  const headerMenuElements = mobileHeader.content.find(
    (item) => item.label === "Mobile Header"
  )?.menuElements

  const utilityMenuElements = mobileHeader.content.find(
    (item) => item.label === "Utility"
  )?.menuElements

  return {
    headerMenuElements,
    utilityMenuElements,
    showLabel: mobileHeader.showLabel,
    hideLabel: mobileHeader.hideLabel,
  }
}
