import { useStaticQuery, graphql } from "gatsby"

import {
  MENU_ELEMENT,
  NAVIGATION_ELEMENT,
} from "constants/ContentfulContentTypes"

export const signup = "/signup?register=1"

const flattenMenu = (menuElements) => {
  let menu = menuElements.map((element) => {
    const menuItem = []
    switch (element.__typename) {
      case MENU_ELEMENT:
        menuItem.push(
          ...element.menuElements.map((item) => {
            let label = (element.label === "Access & Resources" ? item.label : `${element.label}: ${item.label}`)
            return {
              label: label,
              action: item.action,
            }
          })
        )
        break
      case NAVIGATION_ELEMENT:
      default:
        menuItem.push({ label: element.label, action: element.action })
        break
    }
    return menuItem
  })

  // Alternative to .flat() which broke Edge
  let flatMenu = Array.prototype.concat.apply([], menu)
  return flatMenu
}

export const useNextSectionMenuData = () => {
  const { nextSection } = useStaticQuery(graphql`
    query {
      nextSection: contentfulHcpMenuAssembly(label: { eq: "Next Section" }) {
        content {
          ...MenuFragment
          ...NavigationElementFragment
        }
        nextLabel: showLabel
      }
    }
  `)

  const evergreenNavigationElement = nextSection.content.find(
    (item) => item.label === "Register for updates"
  )

  const menuElements = flattenMenu(
    nextSection.content.find((item) => item.label === "Next Section")
      .menuElements
  )

  return {
    evergreenNavigationElement,
    menuElements,
    nextLabel: nextSection.nextLabel,
  }
}
