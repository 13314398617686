export const HCP_PAGE = "ContentfulHcpPage"
export const LINK_ACTION = "ContentfulHcpLinkAction"
export const TELEPHONE_ACTION = "ContentfulHcpTelephoneAction"
export const NAVIGATION_ELEMENT = "ContentfulHcpNavigationElement"
export const MENU_ELEMENT = "ContentfulHcpMenuElement"
export const HCP_TOPIC = "ContentfulHcpTopic"
export const HCP_MENU = "ContentfulHcpMenu"
export const HCP_HERO = "ContentfulHcpHero"
export const HCP_HOME_CARD = "ContentfulHcpHomeCard"
export const HCP_ABOUT_ASSEMBLY_A = "ContentfulHcpAboutAssemblyA"
export const HCP_ABOUT_ASSEMBLY_AV2 = "ContentfulHcpAboutAssemblyAV2"
export const HCP_ABOUT_ASSEMBLY_B = "ContentfulHcpAboutAssemblyB"
export const HCP_ABOUT_ASSEMBLY_C = "ContentfulHcpAboutAssemblyC"
