import React, { useState, useEffect } from "react"
import { Container } from "react-bootstrap"

import { Link } from "gatsby"
import classNames from "classnames"

import {
  useNextSectionMenuData,
  signup,
} from "hooks/contentful/useNextSectionMenuData"
import LetterIcon from "assets/svg/letter-icon"
import LongArrow from "assets/svg/long-arrow"

import styles from "./NextSectionMenu.module.scss"

const NextSectionMenu = () => {
  const {
    evergreenNavigationElement,
    menuElements,
    nextLabel,
  } = useNextSectionMenuData()

  const [showNext, setNext] = useState(false)

  const showOnPages = [
    "about",
    "palisade",
    "ramses",
    "safety",
    "dosing",
    "getstarted",
    "access",
  ]

  useEffect(() => {
    showOnPages.find((element, index) => {
      if (
        element === window.location.pathname.replace(/^\/|\/$/g, "") &&
        index < menuElements.length - 1
      ) {
        setNext(true)
      }
    })
  })

  if (!showNext) return null

  return (
    <Container fluid as={"section"} className={styles.nextSection}>
      <Container as={"nav"} className={styles.nav}>
        {menuElements.map((element) => {
          const urlPath = element.action.url.replace(/^\/|\/$/g, "")
          const windowPath = window.location.pathname.replace(/^\/|\/$/g, "")
          return (
            <Link
              className={classNames([styles.nextSectionLink], {
                [styles.current]: urlPath === windowPath,
              })}
              key={element.label}
              to={`${element.action.url}`}
            >
              {`${nextLabel}: ${element.label}`}
              <LongArrow classes={styles.longArrow} />
            </Link>
          )
        })}
      </Container>
    </Container>
  )
}

export default NextSectionMenu
