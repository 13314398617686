import React, { useEffect, useRef, useState } from "react"
import { Container } from "react-bootstrap"

import { useHeaderData } from "hooks/contentful/useHeaderData"

import UtilityNav from "./UtilityNav/UtilityNav"
import MobileUtilityNav from "./MobileUtilityNav/MobileUtilityNav"

import HeaderNav from "./HeaderNav/HeaderNav"

import styles from "./Header.module.scss"

const EvergreenMessage = ({ message, reference }) => (
  <Container
    ref={reference}
    fluid
    role="banner"
    as={"section"}
    className={styles.audienceContainer}
  >
    <Container>
      <p className={styles.audience}>{message}</p>
    </Container>
  </Container>
)

const Header = () => {
  const { evergreenMessage } = useHeaderData()
  const evergreenReference = useRef(null)
  const utilityReference = useRef(null)
  const [headerOffset, setHeaderOffset] = useState(0)

  useEffect(() => {
    setHeaderOffset(
      evergreenReference.current.offsetHeight +
        utilityReference.current.offsetHeight
    )
  }, [evergreenReference, utilityReference])

  return (
    <>
      <EvergreenMessage
        reference={evergreenReference}
        message={evergreenMessage}
      />
      <UtilityNav />
      <MobileUtilityNav reference={utilityReference} />
      <HeaderNav headerOffset={headerOffset} />
    </>
  )
}

export default Header
