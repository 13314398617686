import React from "react"
import { Container } from "react-bootstrap"

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import { useFooterData } from "hooks/contentful/useFooterData"
import { formatHref } from "utils/helpers"
import { EXTERNAL_RICH_TEXT_OPTIONS } from "utils/contentfulRenderer"

import FooterNav from "./FooterNav/FooterNav"
import ExternalLink from "components/Modal/ExternalLink"

import styles from "./Footer.module.scss"

const Footer = () => {
  const {
    menuElements,
    questions,
    homeNavAction,
    copyright,
    trademarks,
    evergreenMessage,
  } = useFooterData()

  return (
    <Container role={"contentinfo"} aria-label={"footer"} fluid as={"section"} className={styles.footer}>
      <Container>
        <footer className={styles.footerMenu}>
          <FooterNav menuElements={menuElements} />
          <div className={styles.richSubText}>
            {documentToReactComponents(questions, EXTERNAL_RICH_TEXT_OPTIONS)}
          </div>
            <ExternalLink
              className={styles.logo}
              url={formatHref(homeNavAction.action.url)}
            >
              <img alt="Aimmune Therapeutics logo" src={homeNavAction.imageURL} />
            </ExternalLink>
          <p className={styles.copyright}>
            {`© ${/*new Date().getFullYear()*/'2023'} ${copyright}`}
          </p>
          <p className={styles.trademarks}>{trademarks}</p>
          <p className={styles.evergreenMessage}>{evergreenMessage}</p>
        </footer>
      </Container>
    </Container>
  )
}

export default Footer
