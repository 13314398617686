import { useEffect } from "react"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"

// TODO: AN
// Currently this breaks on ios. maybe needs custom
// implementation to only target content and not mobile header nav.

// Targeting the mobile-nav via ID

export const useScrollLock = (isLocked) => {
  useEffect(() => {
    const targetElement = document.querySelector('#mobile-nav');
    if (isLocked) {
      disableBodyScroll(targetElement)
    } else {
      enableBodyScroll(targetElement)
    }
  }, [isLocked])
}
