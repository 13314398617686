import React from "react"

const ExternalLink = ({ classes }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 11 11"
    className={classes}
  >
    <path
      d="M5.8,5.5c0.1,0,0.2,0,0.3-0.1L10,1.4v2c0,0.2,0.2,0.4,0.4,0.4c0.2,0,0.4-0.2,0.4-0.4V0.4
	c0-0.2-0.2-0.4-0.4-0.4H7.4C7.2,0,7,0.2,7,0.4s0.2,0.4,0.4,0.4h2l-4,3.9C5.3,4.9,5.3,5.1,5.4,5.2S5.6,5.5,5.8,5.5L5.8,5.5z"
    />
    <path
      d="M10.5,5.9c-0.2,0-0.4,0.2-0.4,0.4v3.5c0,0.2-0.1,0.3-0.3,0.3H1.1c-0.2,0-0.3-0.1-0.3-0.3V1.1
	C0.8,1,1,0.8,1.1,0.8h3.3c0.2,0,0.4-0.2,0.4-0.4S4.6,0,4.4,0H1.1C0.5,0,0,0.5,0,1.1v8.7c0,0.6,0.5,1.1,1.1,1.1h8.7
	c0.6,0,1.1-0.5,1.1-1.1V6.3c0-0.1,0-0.2-0.1-0.3C10.7,5.9,10.6,5.9,10.5,5.9z"
    />
  </svg>
)

export default ExternalLink
