import React from "react"
import { Container } from "react-bootstrap"

import { Link } from "gatsby"

import CTAArrow from "assets/svg/cta-arrow"
import DownloadIcon from "assets/svg/download-icon"

import styles from "./CaptionCTA.module.scss"

const CaptionCTA = ({ header, navigationElement }) => {
  return (
    <Container fluid as={"section"} className={styles.container}>
      <Container>
        <div className={styles.seeResults}>
          <h3 className={styles.caption}>{header.title}</h3>
          {navigationElement.target ? (
              <a className={styles.cta} href={navigationElement.action.url} target={navigationElement.target}>
                {navigationElement.label}
                <DownloadIcon classes={`${styles.icon} ${styles.downloadIcon}`} />
              </a>
          ) : (
            <Link className={styles.cta} to={navigationElement.action.url}>
              {navigationElement.label}
              {navigationElement.showIcon ? (
                <DownloadIcon classes={`${styles.icon} ${styles.downloadIcon}`} />
              ) : (
                <CTAArrow classes={styles.icon} />
              )}
            </Link>
          )}
        </div>
      </Container>
    </Container>
  )
}

export default CaptionCTA
