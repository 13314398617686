import { useStaticQuery, graphql } from "gatsby"

import { TELEPHONE_ACTION } from "constants/ContentfulContentTypes"

export const useHeaderData = () => {
  const { header } = useStaticQuery(graphql`
    query {
      header: contentfulHcpMenuAssembly(label: { eq: "Header" }) {
        content {
          ...MenuFragment
          ...NavigationElementFragment
          ...TopicFragment
        }
      }
    }
  `)

  const palforziaOverview = {
    action: {
      label: "Palforzia Overview",
      type: "ContentfulHcpPage",
      url: "/palforziaoverview",
      useNewWindow: false
    },
    alt: null,
    image: null,
    label: "Palforzia Overview",
    showIcon: false,
    __typename: "ContentfulHcpNavigationElement"
  }

  const evergreenMessage = header.content.find(
    (item) => item.title === "Intended Audience"
  )?.content
    
  const utilityMenuElements = header.content
    .find((item) => item.label === "Utility")
    ?.menuElements.filter((element) => element.action.type !== TELEPHONE_ACTION)


  const mobileUtilityMenuElements = header.content.find(
    (item) => item.label === "Mobile Utility"
  )?.menuElements

  const homeLogo = header.content.find((item) => item.label === "Home")

  const menuElements = header.content.find((item) => item.label === "Header")
    ?.menuElements

  return {
    evergreenMessage,
    utilityMenuElements,
    mobileUtilityMenuElements,
    homeNavAction: {
      action: { ...homeLogo?.action },
      imageURL: homeLogo?.image?.localFile?.publicURL,
      alt: homeLogo.alt
    },
    menuElements,
  }
}
