import React from "react"

const LongArrow = ({ classes }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="38"
    height="26"
    viewBox="0 0 38 26"
    className={classes}
  >
    <path
      d="M37.6,13c0-0.4-0.1-0.7-0.3-0.9l-9-11c-0.4-0.6-1.4-0.7-2-0.2c-0.6,0.5-0.6,1.4-0.2,1.9l7.1,8.8H1.7
	c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4h31.6l-7.1,8.8c-0.5,0.5-0.4,1.5,0.2,2c0.4,0.4,1.6,0.3,1.9-0.3l9-11
	C37.6,13.6,37.6,13.4,37.6,13z"
    />
  </svg>
)

export default LongArrow
