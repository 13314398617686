import { useStaticQuery, graphql } from "gatsby"

export const useFooterData = () => {
  const { footer } = useStaticQuery(graphql`
    query {
      footer: contentfulHcpMenuAssembly(label: { eq: "Footer" }) {
        content {
          ...MenuFragment
          ...NavigationElementFragment
          ...RichTopicFragment
          ...TopicFragment
        }
      }
    }
  `)

  // console.log('footer ', footer.content);
  const menuElements = footer.content.find((item) => item.label === "Footer")
    ?.menuElements
  
  // console.log('menuE ', menuElements);

  const questions = footer.content.find((item) => item.title === "Questions")
    ?.richContent?.json

  const brandLogo = footer.content.find((item) => item.label === "Aimmune")

  const copyright = footer.content.find((item) => item.title === "Copyright")
    ?.content

  const trademarks = footer.content.find((item) => item.title === "Trademarks")
    ?.content

  const evergreenMessage = footer.content.find(
    (item) => item.title === "Intended Audience"
  )?.content

  return {
    menuElements,
    questions,
    homeNavAction: {
      action: { ...brandLogo.action },
      imageURL: brandLogo.image?.localFile?.publicURL,
    },
    evergreenMessage,
    trademarks,
    copyright,
  }
}
