import React, { useEffect, useRef } from "react"

import classNames from "classnames"

import { useMobileHeaderData } from "hooks/contentful/useMobileHeaderData"
import { useScrollLock } from "hooks/useScrollLock"
import { formatHref } from "utils/helpers"
import {
  NAVIGATION_ELEMENT,
  MENU_ELEMENT,
} from "constants/ContentfulContentTypes"

import ExternalLink from "components/Modal/ExternalLink"

import styles from "./MobileHeaderNav.module.scss"

const MobileHeaderNav = ({ show, headerHeight }) => {
  const { headerMenuElements, utilityMenuElements } = useMobileHeaderData()

  const ref = useRef(null)

  useEffect(() => {
    if (show) {
      ref.current.scrollTop = 0
    }
  }, [show])

  useScrollLock(show)

  const renderNavigationElement = (
    { label, action, showIcon, image },
    index
  ) => {
    return (
      <li key={index} className={styles.menuItem}>
        {action.useNewWindow && showIcon ? (
          <ExternalLink
            url={formatHref(action.url)}
            className={classNames([styles.anchor], {
              [styles.anchorWithIcon]: image?.localFile?.publicURL,
            })}
          >
            {label}
            {showIcon && (
              <span
              className={classNames([styles.icon], {
                [styles.external]: action.useNewWindow,
              })}
            />
            )}
          </ExternalLink>
        ) : (
        <a
          className={classNames([styles.anchor], {
            [styles.anchorWithIcon]: image?.localFile?.publicURL,
          })}
          target={action.useNewWindow && "_blank"}
          href={formatHref(action.url)}
        >
          {image?.localFile?.publicURL && (
            <img
              className={styles.anchorIcon}
              alt=""
              src={image?.localFile?.publicURL}
            />
          )}
          {label}
        </a>
        )}
      </li>
    )
  }

  const renderMenuElement = ({ label, menuElements }, index) => {
    return (
      <li key={index} className={styles.submenuItem}>
        <ul className={styles.submenu}>
          {menuElements.map((element, subMenuIndex) => {
            return renderNavigationElement(
              {
                ...element,
                label: label ? `${label}: ${element.label}` : element.label,
              },
              subMenuIndex
            )
          })}
        </ul>
      </li>
    )
  }

  return (
    <aside
      ref={ref}
      className={classNames([styles.container], {
        [styles.show]: show,
      })}
      id="mobile-nav"
      style={{
        top: `${headerHeight}px`,
        height: `calc(100vh - ${headerHeight}px)`,
      }}
    >
      <nav className={styles.nav}>
        <ul className={styles.mainNav}>
          {headerMenuElements.map((element, index) => {
            let renderedMenuItem

            switch (element.__typename) {
              case MENU_ELEMENT:
                renderedMenuItem = renderMenuElement(element, index)
                break
              case NAVIGATION_ELEMENT:
                renderedMenuItem = renderNavigationElement(element, index)
                break
              default:
                break
            }

            return renderedMenuItem
          })}
        </ul>
        <ul className={styles.utilityNav}>
          {utilityMenuElements.map((element, index) =>
            renderNavigationElement(element, index)
          )}
        </ul>
      </nav>
    </aside>
  )
}

export default MobileHeaderNav
