// Cookie ID
export const CONSENT_COOKIE_ID = "gatsby-gdpr-google-analytics"

// Layout
export const XL_DEVICE_WIDTH = 1200
export const LG_DEVICE_WIDTH = 992
export const MD_DEVICE_WIDTH = 768
export const SM_DEVICE_WIDTH = 576
export const DEVICE_WIDTHS = [
  XL_DEVICE_WIDTH,
  LG_DEVICE_WIDTH,
  MD_DEVICE_WIDTH,
  SM_DEVICE_WIDTH,
]

export const XL_MAX_WIDTH = 1140
export const LG_MAX_WIDTH = 960
export const MD_MAX_WIDTH = 720
export const SM_MAX_WIDTH = 540
export const MAX_WIDTHS = [
  XL_MAX_WIDTH,
  LG_MAX_WIDTH,
  MD_MAX_WIDTH,
  SM_MAX_WIDTH,
]

//export const FOOTNOTE_SYMBOLS = ["*", "†", "‡", "§", "¶", "#"]

export const FOOTNOTE_SYMBOLS = ["*", "†", "","‡", "§", "¶", "#"]