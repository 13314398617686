import React from "react"

const MinusSign = ({ classes }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    className={classes}
  >
    <line x1="22.1" y1="14" x2="6" y2="14" />
  </svg>
)

export default MinusSign
