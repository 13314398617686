import React from "react"

import PropTypes from "prop-types"

import { UPDATE_EXTERNAL_LINK } from "constants/StateActionTypes"
import { useStateValue } from "state/state"

const ExternalLink = ({ children, url, className }) => {
  const [, dispatch] = useStateValue()
  return (
    <a
      href={url}
      className={className}
      target="_blank"
      onClick={(e) => {
        e.preventDefault()
        dispatch({
          type: UPDATE_EXTERNAL_LINK,
          url,
        })
      }}
    >
      {children}
    </a>
  )
}

ExternalLink.propTypes = {
  children: PropTypes.node.isRequired,
  url: PropTypes.string.isRequired,
}

export default ExternalLink
