import React, { useState, useEffect } from "react"
import { Container } from "react-bootstrap"

import PropTypes from "prop-types"
import { ScrollTrigger, gsap } from "gsap/all"

import { useStateValue } from "state/state"
import { useScrollLock } from "hooks/useScrollLock"
import { useCheckConsentCookie } from "hooks/useCheckConsentCookie"
import { useShowOverlay } from "hooks/useShowOverlay"
import { useSeeResultsMenuData } from "hooks/contentful/useSeeResultsMenuData"

import Header from "./Header/Header"
import Footer from "./Footer/Footer"
import CaptionCTA from "./CaptionCTA/CaptionCTA"
import NextSectionMenu from "./NextSectionMenu/NextSectionMenu"
import References from "./References/References"
import Modal from "./Modal/Modal"
import ISI from "./ISI/ISI"
import ISITray from "./ISITray/ISITray"

import styles from "./Layout.module.scss"

gsap.registerPlugin(ScrollTrigger)

const Layout = ({
  children,
  referencesHeader,
  references = [],
  showSeeResultsMenu = false,
}) => {
  const [{ showOverlay, externalLink, needConsent }] = useStateValue()
  const [showISITray, setShowISITray] = useState(true)

  const captionCTA = useSeeResultsMenuData()
  useCheckConsentCookie()
  useScrollLock(showOverlay)
  useShowOverlay(showOverlay, needConsent || externalLink)

  useEffect(() => {
    const scrollTrigger = ScrollTrigger.create({
      trigger: "#content",
      start: "bottom bottom-=200px",
      endTrigger: "html",
      end: "bottom top",
      onToggle: (status) => setShowISITray(!status.isActive),
    })

    const timer = setTimeout(() => {
      scrollTrigger.refresh()
    }, 1000)

    return () => {
      clearTimeout(timer)
      scrollTrigger.kill()
    }
  }, [setShowISITray])

  return (
    <Container fluid className={styles.layout}>
      <Modal />
      <Header />
      <section id={"content"} className={styles.content}>
        {children}
        {showSeeResultsMenu && <CaptionCTA {...captionCTA} />}
        <NextSectionMenu />
      </section>
      <ISI />
      {references.length > 0 && (
        <References
          referencesHeader={referencesHeader}
          references={references}
        />
      )}
      <Footer />
      <ISITray show={showISITray} />
    </Container>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
