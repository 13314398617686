import React from "react"

const DownloadIcon = ({ classes }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    className={classes}
  >
    <path
      id="Path-Download-Icon"
      d="M17.2,12.8c-0.4,0-0.7,0.3-0.7,0.7v2.6c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.2,0.1-0.2,0.1H1.7
	c-0.2,0-0.3-0.2-0.3-0.3v-2.6c0-0.4-0.3-0.7-0.7-0.7S0,13.1,0,13.5v2.6c0,0.9,0.8,1.7,1.7,1.7h14.4c0.9,0,1.7-0.8,1.7-1.7v-2.6
	C17.8,13.1,17.5,12.8,17.2,12.8z"
    />
    <path
      id="Download-Icon"
      d="M8.4,13.5L8.4,13.5c0.2,0.1,0.3,0.2,0.5,0.2l0,0c0.2,0,0.4,0,0.5-0.2l4.1-4.1
	c0.2-0.2,0.3-0.4,0.2-0.7c-0.1-0.2-0.3-0.4-0.5-0.5c-0.2-0.1-0.5,0-0.7,0.2l-2.9,2.9V0.7c0-0.2-0.1-0.5-0.3-0.6C9,0,8.8,0,8.6,0.1
	C8.3,0.2,8.2,0.5,8.2,0.7v10.7L5.3,8.5c-0.3-0.3-0.7-0.2-1,0c-0.3,0.3-0.3,0.7,0,1L8.4,13.5z"
    />
  </svg>
)

export default DownloadIcon
